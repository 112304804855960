import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import Layout from '../../components/layout/layout';
import { InternalLink } from '../../components/layout/links';
import { PostListStyled, PostLinkStyled, Description } from '../../components/posts/post-list.js';
export const _frontmatter = {
  "slug": "/poetry"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = (props, location) => <Layout title="Poetry" description="The homepage for and introduction to the poetry of D.S. Chapman." type="📚" location={location} {...props} />;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "books"
    }}>{`Books`}</h2>
    <PostListStyled mdxType="PostListStyled">
  <PostLinkStyled key="/poetry/seasons-of-thought" mdxType="PostLinkStyled">
    <InternalLink to="/poetry/seasons-of-thought" mdxType="InternalLink">
      Seasons of Thought
    </InternalLink>
    <Description mdxType="Description">
      A collection of poetry reflecting on the seasons, nature, childhood,
      family, and faith.
    </Description>
  </PostLinkStyled>
    </PostListStyled>
    <h2 {...{
      "id": "poems"
    }}>{`Poems`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/poetry/seasons-of-thought/evergreen"
        }}>{`Evergreen`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/poetry/to-the-green-rock"
        }}>{`To the Green Rock`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/poetry/ink"
        }}>{`Ink`}</a>{`. First published by `}<a parentName="li" {...{
          "href": "http://www2.gcc.edu/orgs/TheQuad/"
        }}>{`The Quad`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/poetry/first-thought"
        }}>{`First Thought`}</a>{`. First published by `}<a parentName="li" {...{
          "href": "http://www2.gcc.edu/orgs/TheQuad/"
        }}>{`The Quad`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/poetry/a-benediction-on-marriage"
        }}>{`A Benediction on Marriage`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/poetry/seasons-of-thought/evening-praise"
        }}>{`Evening Praise`}</a></li>
    </ul>
    <p><a parentName="p" {...{
        "href": "/poetry/all"
      }}>{`See all poems `}{`→`}</a><br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "/poetry/tags"
      }}>{`See all tags `}{`→`}</a></p>
    <p>{`If you prefer to see my prose about poetry, check out the `}<a parentName="p" {...{
        "href": "/articles/tag/poetry"
      }}>{`articles I’ve tagged with poetry.`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      